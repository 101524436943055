import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Banner
import SeamossHome from "../images/seamoss-home-laptop.png"
import ReconBanner from "../images/recon-banner.png"
import TafheemBanner from "../images/tafheem-banner.png"

// Style Guide
import Colors from "../images/seamoss_assets/colors.png"
import Typography from "../images/seamoss_assets/typography.png"
import Buttons from "../images/seamoss_assets/buttons.png"
import Icons from "../images/seamoss_assets/icons.png"

// Sketches
import Sketch3 from "../images/seamoss_assets/sketch-3.jpeg"
import Sketch2 from "../images/seamoss_assets/sketch-2.jpeg"
import Sketch1 from "../images/seamoss_assets/sketch-1.jpeg"

// Wireframes
import HomePageWireframe from "../images/seamoss_assets/homepage-wireframe-initial.png"
import HomePageWireframeFinal from "../images/seamoss_assets/homepage-wireframe-final.png"
import ProductPageWireframe from "../images/seamoss_assets/productpage-wireframe.png"
import HomePageWireframeMobile from "../images/seamoss_assets/homepage-wireframe-m.png"
import ProductPageWireframeMobile from "../images/seamoss_assets/productpage-wireframe-m.png"

// UI Design
import HomePage from "../images/seamoss_assets/homepage.png"
import ProductPage1 from "../images/seamoss_assets/product-page-1.png"
import ProductPage2 from "../images/seamoss_assets/product-page-2.png"
import HomePageMobile from "../images/seamoss_assets/homepage-m.png"
import ProductPageMobile from "../images/seamoss_assets/productpage-m.png"
import HomepageCommentary from "../images/seamoss_assets/homepage-commentary.png"

// Figma GIF
import AutoLayout from "../images/seamoss_assets/auto-layout.gif"
import Variants from "../images/seamoss_assets/variants.gif"
import Handoff from "../images/seamoss_assets/handoff.png"

// CSS
import "../components/layout.css"
import Banner from "../components/banner"
import Tag from "../components/tag"
import ScrollToTop from "../components/scrollToTop"

const seamoss_ecommerce = () => (
  <Layout>
    <SEO title="SeaMoss ecommerce" />
    <div>
      <div className="top-banner background-green d-flex align-items-center justify-content-center flex-column py-8">
        <img className="w-sm-100 w-40" src={SeamossHome} alt={"SeamossHome"} />
      </div>
      <div className="container-md">
        <div className="d-flex justify-content-evenly flex-sm-col py-8 px-sm-2">
          <div className="pr-3 flex-basis-7">
            <div className="portfolio-detail-intro-head pb-2">
              OVERVIEW & BACKGROUND
            </div>
            <div className="portfolio-detail-intro-p">
              SeaMoss Transformation began the branding of their products &
              ecommerce store with the vision of becoming a leading brand in the
              health and nutrition market.
            </div>
            <div className="portfolio-detail-intro-p mt-2">
              The goal was to redesign their website using proper style guide 🎨
              while improving the user experience & conversion rate on their
              homepage and product page for customer engagement.
            </div>
            <div className="mt-4">
              <div className="portfolio-detail-intro-head pb-2">
                SEE PROTOTYPE
              </div>
              <div className="d-flex">
                <a
                  target={"_blank"}
                  className="btn-link portfolio-detail-intro-p mr-1"
                  href="https://www.figma.com/proto/dLYGpmEXx2RdgDYTZeC4hR/SeaMoss-Web-Demo?page-id=0%3A1&node-id=1%3A124&viewport=241%2C48%2C0.04&scaling=min-zoom&starting-point-node-id=1%3A124"
                  alt="prototype-web"
                >
                  Web
                </a>
                <a
                  target={"_blank"}
                  className="btn-link light portfolio-detail-intro-p"
                  href="https://www.figma.com/proto/flQnHJBPO9o4Wc58N4pQTj/SeaMoss-Demo-Mobile?page-id=0%3A1&node-id=2%3A1011&viewport=241%2C48%2C0.21&scaling=min-zoom&starting-point-node-id=2%3A1011"
                  alt="prototype-mobile"
                >
                  Mobile
                </a>
              </div>
            </div>
          </div>
          <div className="flex-basis-3 mt-sm-4">
            <div className="portfolio-detail-intro-head pb-2">ROLE & TIME</div>
            <div className="portfolio-detail-intro-head pb-1 border-bottom color-dark-blue">
              UI/UX Designer
            </div>
            <div className="portfolio-detail-intro-p border-bottom pb-1 pt-1">
              interaction Design, Visual Design, Wireframing, Prototyping Style
              Guide, UI Kit
            </div>
            <div className="portfolio-detail-intro-p pb-1 pt-1">Nov 2021</div>
          </div>
        </div>
      </div>
      <div className="top-banner background-blue d-flex align-items-center py-8 px-sm-2">
        <div className="container-md">
          <div className="portfolio-detail-banner-head pt-2 pb-2">
            {" "}
            <span role="img">🌄</span> Background
          </div>
          <div className="portfolio-detail-intro-p pb-1 pt-1 ml-3">
            <div className="pb-2">
              SeaMoss Transformation is an{" "}
              <b className="f-f-p-b">ecommerce nutrition </b> brand that helps
              people stay healthy and in shape through their range of edible
              products.
            </div>
            <div className="pb-2">
              This was a <b className="f-f-p-b">part time </b> project that I
              did where I spent a large amount of time outside of work hours and
              mostly on weekends. I worked closely with a small team of a{" "}
              <b className="f-f-p-b">Developer</b>, a{" "}
              <b className="f-f-p-b">Data Analyst/CRO </b>, a{" "}
              <b className="f-f-p-b">Product Owner</b> & 2{" "}
              <b className="f-f-p-b">stakeholders</b> to shape up the user
              experience of their website.
            </div>
            <div className="pb-1">Some key achievements are listed below:</div>
            <ul>
              <li>
                <b className="f-f-p-b">
                  Establishing a style guide & design kit{" "}
                </b>
                : This has helped to maintain consistency in the look and feel
                across the website.
              </li>
              <li>
                <b className="f-f-p-b">Improved Customer Engagement & Trust</b>:
                On homepage and product page, added some sections like instagram
                post & review sections to engage customers.
              </li>
              <li>
                <b className="f-f-p-b">Improved Conversion Rate</b>: Introduced
                responsive design as well as some sections on product page which
                increased conversion rate.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container-md d-flex flex-column align-items-center py-5 px-sm-2">
        <ol className="m-0">
          <div className="portfolio-detail-head pb-2">
            <span role="img">♻️</span> The Process
          </div>
          <div className="ml-2">
            <div className="portfolio-detail-intro-p mt-1">
              <div className="pb-1 ml-1">
                We did ux audit & looked at the data and uncovered some pain
                points felt by individuals. It was revealed that:
              </div>
              <ul className="ml-4">
                <li>
                  50+ percent of the traffic on the web comes from mobile phones
                  and we didn't have a responsive website.
                </li>
                <li>
                  Website was very inconsistent and didn't give a vibe of a
                  brand who wants to lead the market.
                </li>
                <li>
                  No imagery of brand on the homepage didn't give an organic
                  brand vibe.
                </li>
                <li>No social proof for the product being good.</li>
                <li>
                  No customer engagement on the product page to suggest more
                  products to users.
                </li>
              </ul>
            </div>
          </div>
        </ol>
      </div>
      <div className="container-md d-flex flex-column align-items-center py-5 px-sm-2">
        <ol className="m-0">
          <div className="portfolio-detail-head pb-2">
            <span role="img">💡</span> Product Vision and solution
          </div>
          <div className="ml-2">
            <div className="portfolio-detail-intro-p mt-1">
              <div className="pb-1 ml-1">
                We did some market research on competitors to investigate the
                current market and take inspiration from some of their features
                as well as saw website data and identified where we’re lacking .
                From these findings, we identified some key business goals:
              </div>
              <ul className="ml-4">
                <li>
                  We need a responsive website specially targeting mobile phones
                  to increase traffic coming from smaller devices.
                </li>
                <li>
                  We need a style guide to redesign clean, simple and friendly
                  website based on brand values.
                </li>
                <li>
                  We need to add an instagram post section to give a sense of
                  community and originality.
                </li>
                <li>
                  We need to add Blog section on homepage to promote health
                  through SeaMoss.
                </li>
                <li>
                  we need to use product images throughout the website to
                  develop brand value.
                </li>
                <li>
                  We need to add a customer testimonials section on homepage &
                  reviews section on product page to increase trust of customers
                  by social proof.
                </li>
                <li>
                  We need to add a “You may also like” section at the end of the
                  product page to engage customers into buying more products.
                </li>
              </ul>
            </div>
          </div>
        </ol>
      </div>
      <div className="container-md d-flex flex-column align-items-center py-5 px-sm-2">
        <div>
          <div className="portfolio-detail-head pb-2">
            <span role="img">🧱 </span> Defining Initial Solution
          </div>
          <div>
            <div className="portfolio-detail-intro-p mt-1 ml-3">
              We did some sketching/wireframing sessions and presented to the
              stakeholders and told them how these solutions can improve the
              overall conversions & user experience aligning with the business
              goals.
            </div>
          </div>
          <div className="portfolio-detail-intro-head mt-4">Rough Sketches</div>
          <div className="d-flex flex-row flex-sm-col mt-2">
            <div className="card-shadow background-yellow flex-row mr-2 mt-sm-2">
              <div>
                <div className="portfolio-detail-intro-head mb-1">
                  Homepage initial
                </div>
                <div>
                  <img
                    className="m-0 max-width-sm-100"
                    src={Sketch3}
                    alt={"Sketch3"}
                  />
                </div>
              </div>
            </div>
            <div className="card-shadow background-blue flex-row mr-2 mt-sm-2">
              <div>
                <div className="portfolio-detail-intro-head mb-1">Homepage</div>
                <div>
                  <img
                    className="m-0 max-width-sm-100"
                    src={Sketch1}
                    alt={"Sketch1"}
                  />
                </div>
              </div>
            </div>
            <div className="card-shadow background-green flex-row mr-2 mt-sm-2">
              <div>
                <div className="portfolio-detail-intro-head mb-1">
                  Productpage
                </div>
                <div>
                  <img
                    className="m-0 max-width-sm-100"
                    src={Sketch2}
                    alt={"Sketch2"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="portfolio-detail-intro-head mt-4">
            Wireframes (Web)
          </div>
          <div className="d-flex flex-row flex-sm-col mt-2">
            <div className="card-shadow card-scrollable background-green flex-row mr-2 mt-sm-2">
              <div>
                <div className="portfolio-detail-intro-head mb-1">
                  Home Page
                </div>
                <div>
                  <img
                    className="m-0 max-width-sm-100"
                    src={HomePageWireframeFinal}
                    alt={"ProductPageWireframe"}
                  />
                </div>
              </div>
            </div>
            <div className="card-shadow card-scrollable background-yellow flex-row mr-2 mt-sm-2">
              <div>
                <div className="portfolio-detail-intro-head mb-1">
                  Product Page
                </div>
                <div>
                  <img
                    className="m-0 max-width-sm-100"
                    src={ProductPageWireframe}
                    alt={"HomePageWireframeFinal"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="portfolio-detail-intro-head mt-4">
            Wireframes (Mobile)
          </div>
          <div className="d-flex flex-row flex-sm-col mt-2">
            <div className="card-shadow card-scrollable background-radish flex-row mr-2 mt-sm-2">
              <div>
                <div className="portfolio-detail-intro-head mb-1">
                  Home Page
                </div>
                <div>
                  <img
                    style={{ maxWidth: "275px" }}
                    className="m-0 max-width-sm-100"
                    src={HomePageWireframeMobile}
                    alt={"HomePageWireframe"}
                  />
                </div>
              </div>
            </div>
            <div className="card-shadow card-scrollable background-purple flex-row mr-2 mt-sm-2">
              <div>
                <div className="portfolio-detail-intro-head mb-1">
                  Product Page
                </div>
                <div>
                  <img
                    style={{ maxWidth: "275px" }}
                    className="m-0 max-width-sm-100"
                    src={ProductPageWireframeMobile}
                    alt={"HomePageWireframe"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="portfolio-detail-intro-p mt-4">
            After some iterations of the product page & Homepage, we were able
            to get approval for the design. Inorder for the developer to start
            the work on architecture, I created the prototype of wireframes and
            shared them with him.{" "}
          </div>
        </div>
      </div>
      <div className="banner-light px-8 pt-4 px-sm-2">
        <div>
          <div className="portfolio-detail-banner-head pb-1">
            <span role={"img"}>🖌</span> Design
          </div>
          <div className="portfolio-detail-intro-p ml-3">
            First order of business after finalizing wireframes was to create a
            clean, consistent and friendly style guide for the brand.
          </div>
          <div className="portfolio-detail-head pb-1 mt-4">
            <span role={"img"}>🎨</span> Style Guide & UI Kit
          </div>
          <div className="portfolio-detail-intro-p ml-3">
            The style guide contains colors, typography, buttons & icons to make
            UI more consistent and attractive.
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center flex-column py-4 px-sm-2">
          <div className="card-shadow flex-column flex-basis-3 bg-white p-2 ">
            <div className="portfolio-detail-head">Colors</div>
            <div className="portfolio-detail-intro-p pt-1">
              We used <b>fern green & white</b> as primary colors as they
              represent nature as well as growth. Very much aligned with the
              product and its vision.
            </div>
            <div className="d-flex">
              <div>
                <img className="m-0" src={Colors} alt={"Colors"} />
              </div>
            </div>
          </div>
          <div className="card-shadow flex-column flex-basis-3 bg-white p-2 mt-2">
            <div className="portfolio-detail-head">Typography</div>
            <div className="portfolio-detail-intro-p pt-1">
              We used <span className="f-f-p-b">Inria Sans (Bold) </span> for
              all the headings & button text and{" "}
              <span className="f-f-p-b">Avenir Next (Regular) </span> for body &
              paragraph text. Here is a showcase of different ways to structure
              content:
            </div>
            <div className="d-flex ">
              <img className="m-0" src={Typography} alt={"TypographyImg"} />
            </div>
          </div>
          <div className="card-shadow flex-column flex-basis-3 bg-white p-2 mt-2">
            <div className="portfolio-detail-head">Buttons</div>
            <div className="portfolio-detail-intro-p pt-1">
              We used multiple types, colors & styles of a button used
              throughout the website to use each one in a different context.
            </div>
            <div className="d-flex mt-2">
              <img className="m-0" src={Buttons} alt={"ButtonsImg"} />
            </div>
          </div>
          <div className="card-shadow flex-column flex-basis-3 bg-white p-2 mt-2">
            <div className="portfolio-detail-head">Icons</div>
            <div className="portfolio-detail-intro-p pt-1">
              We used feather icons (1px) as general throughout the app and
              feather icons (2px) in places where the icons needed emphasis.
            </div>
            <div className="d-flex">
              <div>
                <img className="m-0" src={Icons} alt={"IconsImg"} />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column align-items-center py-5 px-sm-2">
          <div>
            <div className="portfolio-detail-head">
              <span role="img">🖼 </span> UI Design
            </div>
            <div className="portfolio-detail-intro-p pt-1 ml-3">
              After style guide was finalized and reviewed by the team, we
              started UI designing on Figma.
            </div>
            <div className="portfolio-detail-intro-head mt-4">
              UI Design (Web)
            </div>

            <div className="d-flex flex-row flex-sm-col mt-2">
              <div className="card-shadow card-scrollable background-blue flex-row mr-2 mt-sm-2">
                <div>
                  <div className="portfolio-detail-intro-head mb-1">
                    Home Page Design
                  </div>
                  <div>
                    <img
                      className="m-0 max-width-sm-100"
                      src={HomePage}
                      alt={"HomePage"}
                    />
                  </div>
                </div>
              </div>
              <div className="card-shadow card-scrollable background-green flex-row mr-2 mt-sm-2">
                <div>
                  <div className="portfolio-detail-intro-head mb-1">
                    Product Page - One Time
                  </div>
                  <div>
                    <img
                      className="m-0 max-width-sm-100"
                      src={ProductPage1}
                      alt={"ProductPage1"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="portfolio-detail-intro-head mt-4">
              UI Design (Mobile)
            </div>
            <div className="d-flex flex-row flex-sm-col mt-2">
              <div className="card-shadow card-scrollable background-radish flex-row mr-2 mt-sm-2">
                <div>
                  <div className="portfolio-detail-intro-head mb-1">
                    Home Page
                  </div>
                  <div>
                    <img
                      style={{ maxWidth: "275px" }}
                      className="m-0 max-width-sm-100"
                      src={HomePageMobile}
                      alt={"HomePageMobile"}
                    />
                  </div>
                </div>
              </div>
              <div className="card-shadow card-scrollable background-purple flex-row mr-2 mt-sm-2">
                <div>
                  <div className="portfolio-detail-intro-head mb-1">
                    Product Page
                  </div>
                  <div>
                    <img
                      style={{ maxWidth: "275px" }}
                      className="m-0 max-width-sm-100"
                      src={ProductPageMobile}
                      alt={"ProductPageMobile"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="portfolio-detail-head mt-4">
              <span role="img">🔧 </span> Key improvements: HomePage
            </div>
            <div className="mt-4">
              <img
                className="m-0 max-width-sm-100"
                src={HomepageCommentary}
                alt={"HomepageCommentary"}
              />
            </div>
            <div className="portfolio-detail-head mt-4 pb-1">
              <span role="img">🛠 </span> Robust Design
            </div>
            <div className="portfolio-detail-intro-p pb-2 ml-3">
              We used <b>Figma</b> funcationalities like <b>auto layout</b> and{" "}
              <b>variants</b> through out the design to make design more
              reliable, reusable and longterm.
            </div>
            <div className="d-flex flex-row flex-sm-col mt-2">
              <div className="card-shadow flex-row mr-2 mt-sm-2">
                <div>
                  <div className="portfolio-detail-intro-head mb-1">
                    Auto Layout
                  </div>
                  <div>
                    <img
                      className="m-0 max-width-sm-100"
                      src={AutoLayout}
                      alt={"AutoLayout"}
                    />
                  </div>
                </div>
              </div>
              <div className="card-shadow flex-row mr-2 mt-sm-2">
                <div>
                  <div className="portfolio-detail-intro-head mb-1">
                    Button Variants
                  </div>
                  <div>
                    <img
                      className="m-0 max-width-sm-100"
                      src={Variants}
                      alt={"Variants"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-sm-2 px-8 pt-4">
        <div>
          <div className="portfolio-detail-banner-head pb-1">
            <span role={"img"}>🧑🏻‍💻</span> Development
          </div>
          <div className="portfolio-detail-intro-p ml-3">
            After making a style guide, we shared it with the developer to start
            implementing them as well as the redesigned screens after that.
          </div>
          <div className="portfolio-detail-head pb-1 mt-4">
            <span role={"img"}>🛫</span> Developer Handoff
          </div>
          <div className="portfolio-detail-intro-p ml-3">
            I have an onboarding process for developers on Figma, which gives
            them an in depth rundown of everything they need to know to export
            design from Figma.
          </div>
          <div className="mt-4">
            <img
              className="m-0 max-width-sm-100"
              src={Handoff}
              alt={"Handoff"}
            />
          </div>
        </div>
      </div>
      <div className="d-flex py-5 px-8 px-sm-2">
        <ol className="m-0">
          <div className="portfolio-detail-banner-head pb-2">
            <span role="img">🎉</span> Results & Takeaways
          </div>
          <div className="ml-2">
            <div className="portfolio-detail-intro-p mt-1 ml-1">
              <div className="pb-1 mb-1">
                Since the implementation of the new design, we have seen a
                significant increase in the number of sales and increase in a
                traffic from mobile phones.
              </div>
              <div className="pb-1">
                Some key takeaways from this project are:
              </div>
              <ul className="ml-4">
                <li>
                  <b className="f-f-p-b"> Focus on the problem </b>: Even though
                  it is important to make the design much cleaner and modern, in
                  the end it is the users pain points that are the priority to
                  solve first.
                </li>
                <li>
                  <b className="f-f-p-b">
                    Good results come from collaboration
                  </b>
                  : bringing team members to the brainstorm always highlights
                  things that you probably are missing, so it is always good to
                  do it as it solves problems much quicker and much better.
                </li>
                <li>
                  <b className="f-f-p-b">
                    {" "}
                    Design Thinking & Process is effective{" "}
                  </b>
                  : This basic process of ideating, defining & prototyping
                  really helped me focus on the problem and get to the solution
                  quickly.
                </li>
              </ul>
            </div>
          </div>
        </ol>
      </div>
      <div id="work" className="container px-2 bt-light">
        <h2 className="header-h2 text-center mt-3">Checkout more projects</h2>
        <div>
          <div className="banner-container">
            <Banner
              img={ReconBanner}
              alt="recon-banner"
              color="blue"
              route="/recon_design_system"
            >
              <h1>Recon Design System</h1>
              <p>A Fintech Analytical Dashboard</p>
              <div className="tags-container">
                <Tag name="Design System" />
                <Tag name="IA" />
                <Tag name="Style Guide" />
                <Tag name="Fintech" />
              </div>
            </Banner>
          </div>
          <div className="banner-container mobile">
            <Banner
              img={TafheemBanner}
              alt="tafheem-banner"
              color="yellow"
              route="/tafheem_us_sunnah"
            >
              <h1>Tafheem Us Sunnah</h1>
              <p>Redesigning the Mobile App</p>
              <div className="tags-container">
                <Tag name="UI Kit" />
                <Tag name="Visual Design" />
                <Tag name="Mobile App" />
              </div>
            </Banner>
          </div>
        </div>
      </div>
    </div>
    <ScrollToTop />
  </Layout>
)

export default seamoss_ecommerce
